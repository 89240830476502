/* eslint-disable no-underscore-dangle */
import { HYDRATE } from "next-redux-wrapper";
import { PUBLIC_ASSETS_URL } from "@config/config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sanitizeHtml } from "@utils/html";
import { fetchActivePost, fetchAllPosts, fetchCategories, fetchLatestPost, fetchRelatedPosts, fetchTags, searchPosts } from "./actions";
const defaultImage = `${PUBLIC_ASSETS_URL}assets/images/no-image.webp`;

const convertToModel = (posts: CampusPostAPI[]): CampusPostModel[] => posts.map(post => {
  const categories = post._embedded?.["wp:term"]?.filter(term => term.some(e => e.taxonomy.includes("category")))?.[0];
  const category = categories?.[0];
  return {
    id: post.id,
    date: post.date_gmt,
    modified_gmt: post?.modified_gmt ?? post.date_gmt,
    slug: post.slug,
    title: post.title.rendered,
    excerpt: sanitizeHtml(post.excerpt?.rendered || ""),
    image: post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || defaultImage,
    category: category ? {
      id: category.id,
      name: category?.name,
      slug: category.slug
    } : null,
    estimated_reading_time: post.estimated_reading_time,
    post_tag: post._embedded?.["wp:term"]?.[1]?.filter(term => term.taxonomy.includes("tag"))?.map((i: any) => i.name)?.join(", ").toUpperCase() || null,
    old_slugs: post.old_slugs
  };
});

const campusSlicer = createSlice({
  name: "campus",
  initialState: {
    allPosts: {
      posts: ([] as CampusPostModel[]),
      isLoading: true
    },
    latestPosts: {
      posts: ([] as CampusPostModel[]),
      totalPages: 0,
      totalPosts: 0,
      categoryId: (null as number | null),
      tagId: (null as number | null),
      currentPage: 1,
      isLoading: true
    },
    relatedPosts: {
      posts: ([] as CampusPostModel[]),
      currentCategoryId: (null as number | null),
      isLoading: true
    },
    activePost: {
      post: (null as CampusActivePostModel | null),
      isLoading: true
    },
    categories: {
      categories: ([] as CampusCategoryModel[]),
      isLoading: true
    },
    tags: {
      tags: ([] as BlogCategoryModel[]),
      isFetching: false,
      isLoading: true
    },
    searchPosts: {
      posts: ([] as CampusPostModel[]),
      totalPages: 0,
      isLoading: true
    }
  },
  reducers: {},
  extraReducers: {
    [fetchAllPosts.pending.type]: state => {
      state.allPosts.isLoading = true;
    },
    [fetchAllPosts.fulfilled.type]: (state, action: PayloadAction<{
      data: CampusPostAPI[];
      totalPages: number;
    }>) => {
      state.allPosts.posts = state.allPosts.posts.concat(convertToModel(action.payload.data));
      state.allPosts.isLoading = false;
    },
    [fetchLatestPost.pending.type]: state => {
      state.latestPosts.isLoading = true;
      state.latestPosts.posts = [];
    },
    [fetchLatestPost.fulfilled.type]: (state, action: PayloadAction<{
      data: CampusPostAPI[];
      totalPosts: number;
      totalPages: number;
      categoryId: number | null;
      tagId: number | null;
      currentPage: number;
    }>) => {
      state.latestPosts.posts = convertToModel(action.payload.data);
      state.latestPosts.totalPosts = action.payload.totalPosts;
      state.latestPosts.totalPages = action.payload.totalPages;
      state.latestPosts.categoryId = action.payload.categoryId;
      state.latestPosts.tagId = action.payload.tagId;
      state.latestPosts.currentPage = action.payload.currentPage;
      state.latestPosts.isLoading = false;
    },
    [fetchActivePost.pending.type]: state => {
      state.activePost.isLoading = true;
      state.activePost.post = null;
    },
    [fetchActivePost.fulfilled.type]: (state, action: PayloadAction<CampusActivePostAPI>) => {
      if (!action.payload) {
        state.activePost.isLoading = false;
        return;
      }

      const categories = action.payload._embedded?.["wp:term"]?.filter(term => term.some(e => e.taxonomy.includes("category")))?.[0];
      const category = categories?.[0];
      const sanitizedContent = sanitizeHtml(action.payload.content.rendered);
      state.activePost.post = {
        id: action.payload.id,
        date: action.payload.date_gmt,
        modified_gmt: action.payload?.modified_gmt ?? action.payload.date_gmt,
        slug: action.payload.slug,
        title: action.payload.title.rendered,
        content: sanitizedContent,
        image: action.payload._embedded?.["wp:featuredmedia"]?.[0]?.source_url || defaultImage,
        category: category ? {
          id: category.id,
          name: category.name,
          slug: category.slug
        } : null,
        author_name: action.payload.author_meta?.display_name,
        estimated_reading_time: action.payload.estimated_reading_time,
        post_tag: action.payload._embedded?.["wp:term"]?.[1]?.filter(term => term.taxonomy.includes("tag"))?.map((i: any) => i.name)?.join(", ").toUpperCase() || null,
        yoastHead: action.payload.yoast_head,
        old_slugs: action.payload.old_slugs
      };
      state.activePost.isLoading = false;
    },
    [fetchCategories.pending.type]: state => {
      state.categories.isLoading = true;
    },
    [fetchCategories.fulfilled.type]: (state, action: PayloadAction<CampusCategoryAPI[]>) => {
      state.categories.categories = action.payload.map(category => ({
        id: category.id,
        description: category.description,
        name: category.name,
        slug: category.slug
      }));
      state.categories.isLoading = false;
    },
    [fetchTags.pending.type]: state => {
      state.tags.isLoading = true;
      state.tags.tags = [];
    },
    [fetchTags.fulfilled.type]: (state, action: PayloadAction<BlogCategoryAPI[]>) => {
      state.tags.isFetching = true;
      state.tags.tags = action.payload.map(category => ({
        id: category.id,
        description: category.description,
        name: category.name,
        slug: category.slug
      }));
      state.tags.isLoading = false;
    },
    [searchPosts.pending.type]: state => {
      state.searchPosts.isLoading = true;
    },
    [searchPosts.fulfilled.type]: (state, action: PayloadAction<{
      data: CampusPostAPI[];
      totalPages: number;
    }>) => {
      state.searchPosts.posts = convertToModel(action.payload.data);
      state.searchPosts.totalPages = action.payload.totalPages;
      state.searchPosts.isLoading = false;
    },
    [fetchRelatedPosts.pending.type]: state => {
      state.relatedPosts.isLoading = true;
    },
    [fetchRelatedPosts.fulfilled.type]: (state, action: PayloadAction<{
      data: CampusPostAPI[];
      currentCategoryId: number;
    }>) => {
      state.relatedPosts.posts = convertToModel(action.payload.data);
      state.relatedPosts.currentCategoryId = action.payload.currentCategoryId;
      state.relatedPosts.isLoading = false;
    },
    [HYDRATE]: (state, action) => {
      if (action.payload?.campus) {
        if (!action.payload.campus.allPosts.isLoading) {
          state.allPosts = action.payload.campus.allPosts;
        }

        if (!action.payload.campus.latestPosts.isLoading) {
          state.latestPosts = action.payload.campus.latestPosts;
        }

        if (action.payload.campus.activePost.post !== null) {
          state.activePost = action.payload.campus.activePost;
        }

        if (!action.payload.campus.categories.isLoading) {
          state.categories = action.payload.campus.categories;
        }
      }
    }
  }
});
export default campusSlicer;